import { template as template_75b44fcbc09343439f82c6065019600e } from "@ember/template-compiler";
const FKLabel = template_75b44fcbc09343439f82c6065019600e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
